import type { Site } from '$models/Site/Site';
import { getContext, setContext } from 'svelte';

const key = Symbol('site');

export function getSiteContext() {
	return getContext(key) as Site;
}

export function setSiteContext(site: Site) {
	setContext(key, site);
}
