import type { User } from '$models/User/User';
import type { Customer as WebparkingUserData } from '$models/Webparking/Customers';
import { webparking } from '$lib/webparking';

function userToWebparkingUserData(user: User): WebparkingUserData {
	return {
		salutation_id: user.salutation,
		first_name: user.firstName,
		last_name: user.lastName,
		email: user.email,
		password: user.password !== null ? user.password : undefined,
		phone_number: user.phoneNumber,
		company_name: user.companyName,
		coc_number: user.cocNumber,
		vat_number: user.vatNumber,
		subscribe_to_newsletter: user.subscribeToNewsletter,
		subscribe_to_marketing_emails: user.subscribeToMarketingEmails,
		wants_pre_renewal_emails: user.wantsPreRenewalEmails,
		wants_post_renewal_emails: user.wantsPostRenewalEmails
	};
}

function webparkingUserDataToUser(data: WebparkingUserData): User {
	return {
		id: data.id,
		salutation: data.salutation_id,
		firstName: data.first_name,
		lastName: data.last_name,
		email: data.email,
		password: null,
		phoneNumber: data.phone_number,
		customerType: data.company_name === null ? 'private' : 'commercial',
		companyName: data.company_name,
		cocNumber: data.coc_number,
		vatNumber: data.vat_number,
		canPayByInvoice: data.pays_by_invoice ?? false,
		subscribeToNewsletter: data.subscribe_to_newsletter,
		subscribeToMarketingEmails: data.subscribe_to_marketing_emails,
		wantsPreRenewalEmails: data.wants_pre_renewal_emails,
		wantsPostRenewalEmails: data.wants_post_renewal_emails
	};
}

export async function createUser(user: User): Promise<User> {
	const json = await webparking
		.post('customers', {
			json: userToWebparkingUserData(user)
		})
		.json();
	return webparkingUserDataToUser(json.data);
}

export async function updateCurrentUser(user: User): Promise<User> {
	const json = await webparking
		.patch('me', {
			json: userToWebparkingUserData(user),
			throwHttpErrors: false
		})
		.json();
	if ('errors' in json) {
		return json;
	}
	return webparkingUserDataToUser(json.data);
}

export async function getCurrentUser(): Promise<User> {
	const json = await webparking.get('me').json();
	return webparkingUserDataToUser(json.data);
}
