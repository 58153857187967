import type { UserStore, UserStoreData } from '$models/Store/UserStore';
import { getCurrentUser } from '$lib/webparking/users';
import { getContext, setContext } from 'svelte';
import { writable } from 'svelte/store';

function createUserStore() {
	let resolveInitialPromise = null;
	const { subscribe, set, update } = writable(
		new Promise((resolve, reject) => {
			resolveInitialPromise = resolve;
		})
	);
	return {
		subscribe,
		onMount: () => {
			getCurrentUser()
				.then((user) => {
					resolveInitialPromise(user);
					set(Promise.resolve(user));
				})
				.catch(() => {
					resolveInitialPromise(null);
					set(Promise.resolve(null));
				});
		},
		setUser: (user: any) => {
			resolveInitialPromise(user);
			set(Promise.resolve(user));
		}
	};
}

const userStore: UserStore<UserStoreData> = createUserStore();

export function initUserStore() {
	setContext('user', userStore);
}

export function getUserStore() {
	return getContext<UserStore<UserStoreData>>('user');
}
