import type { Order } from '$models/Order/Order';
import type { CartStore, CartStoreData } from '$models/Store/CartStore';
import { getContext, setContext } from 'svelte';
import { writable } from 'svelte/store';

function createCartStore() {
	let resolveInitialPromise = null;
	const { subscribe, set, update } = writable(
		new Promise((resolve, reject) => {
			resolveInitialPromise = resolve;
		})
	);
	return {
		subscribe,
		onMount: () => {
			const cart = JSON.parse(localStorage.getItem('cart'));
			resolveInitialPromise(cart);
			set(Promise.resolve(cart));
		},
		setCart: (cart: Order) => {
			resolveInitialPromise(cart);
			set(Promise.resolve(cart));
			localStorage.setItem('cart', JSON.stringify(cart));
		},
		clearCart: () => {
			resolveInitialPromise(null);
			set(Promise.resolve(null));
			localStorage.removeItem('cart');
		},
		anonymizeCart: (cart: Order) => {
			if (cart === null) {
				resolveInitialPromise(null);
				set(Promise.resolve(null));
				localStorage.removeItem('cart');
				return;
			}
			cart.id = null;
			cart.deliveryDate = null;
			cart.deliveryCompanyName = null;
			cart.billingAddressId = null;
			cart.shippingAddressId = null;
			cart.deliveryNote = null;
			cart.poNumber = null;
			resolveInitialPromise(cart);
			set(Promise.resolve(cart));
			localStorage.setItem('cart', JSON.stringify(cart));
		}
	};
}

const cartStore = createCartStore();

export function initCartStore() {
	setContext('cart', cartStore);
}

export function getCartStore() {
	return getContext<CartStore<CartStoreData>>('cart');
}
