import type { ToastStore, ToastStoreData } from '$models/Store/ToastStore';
import type { Toast } from '$models/Toast/Toast';
import { getContext, setContext } from 'svelte';
import { writable } from 'svelte/store';

function createToastStore() {
	const { subscribe, set, update } = writable<Toast[]>([]);

	function close(id: string) {
		update((toasts) => {
			const toastIndex = toasts.findIndex((t) => t.id === id);
			const toast = toasts[toastIndex];

			if (toast) {
				// Clear any running timeouts
				if (toast.timeoutId) {
					clearTimeout(toast.timeoutId);
				}

				toasts.splice(toastIndex, 1);
			}

			return toasts;
		});
	}

	function trigger(toast: Toast) {
		let id = crypto.randomUUID();
		let newToast: Toast = {
			id,
			autohide: true,
			duration: 5000,
			...toast
		};

		if (newToast.autohide) {
			newToast.timeoutId = setTimeout(() => close(id), newToast.duration);
		}

		update((toasts) => {
			return [...toasts, newToast];
		});
	}

	function clear() {
		set([]);
	}

	return {
		subscribe,
		close,
		trigger,
		clear
	};
}

const toastStore = createToastStore();

export function initToastStore() {
	setContext('toasts', toastStore);
}

export function getToastStore() {
	return getContext<ToastStore<ToastStoreData>>('toasts');
}
