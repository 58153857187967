import { page } from '$app/state';

const ADVOCATE_ID_PARAM = 'advocate-id';

const ADVOCATE_ID_LOCAL_STORAGE_KEY = 'advocate-id';

export function getAffiliateLink(baseUrl: string, id: string) {
	const url = new URL(page.url);

	url.pathname = baseUrl;
	url.searchParams.append(ADVOCATE_ID_PARAM, id);

	return url.href;
}

export function maybeSetAdvocateId() {
	const url = new URL(page.url);
	const advocateId = url.searchParams.get(ADVOCATE_ID_PARAM);

	if (advocateId) {
		// Set the advocate ID to local storage
		setAdvocateId(advocateId);

		// Remove the advocate-id parameter from URL without changing history
		url.searchParams.delete(ADVOCATE_ID_PARAM);
		window.history.replaceState({}, '', url);
	}
}

export async function setAdvocateId(advocateId: string) {
	localStorage.setItem(ADVOCATE_ID_LOCAL_STORAGE_KEY, advocateId);
}

export function getAdvocateId(): string | null {
	return localStorage.getItem(ADVOCATE_ID_LOCAL_STORAGE_KEY);
}
