// TODO: MOVE THE BELOW API CALLS THAT ARE USED DURING BUILD TIME TO SEPARATE
// FILES AND MAKE THEM RETURN MODELS
import type { NonSubscribableProduct } from '$models/Webparking/NonSubscribableProducts';
import type { OrderFrequency } from '$models/Webparking/OrderFrequencies';
import type { OrderQuantity } from '$models/Webparking/OrderQuantities';
import type { ProductCategory } from '$models/Webparking/ProductCategory';
import type { Product } from '$models/Webparking/Products';
import { PUBLIC_WEBPARKING_URL } from '$env/static/public';
import ky from 'ky';

export { listCocSuggestions } from '$lib/webparking/coc-suggestions';
export {
	autoApplyDiscount,
	createOrder,
	createOrderPayment,
	getOrderPrices,
	getOrderPricesDiscountData,
	getOrderPricesProductsPrice,
	listOrderPayments,
	listOrders,
	updateOrder,
	getOrder
} from '$lib/webparking/orders';
export { createPasswordReset, updatePasswordReset } from '$lib/webparking/password-reset';
export { listPaymentMethods } from '$lib/webparking/payment-methods';
export { createSession, deleteCurrentSession } from '$lib/webparking/sessions';
export {
	calculateSubscriptionPrice,
	changeSubscriptionPaymentMethod,
	listPotentialShipmentDates,
	listSubscriptions,
	updateSubscription
} from '$lib/webparking/subscriptions';
export {
	createUser,
	createUserAddress,
	deleteUserAddress,
	getCurrentUser,
	listUserAddresses,
	listUserAddressesByType,
	updateCurrentUser,
	updateUserAddress
} from '$lib/webparking/users';

export const webparking = ky.create({
	credentials: 'include',
	prefixUrl: PUBLIC_WEBPARKING_URL + 'v1',
	headers: {
		Accept: 'application/json'
	},
	retry: 0
});

export async function getProductCategories(): Promise<ProductCategory[]> {
	const json = await webparking.get('product-categories').json();
	return json.data;
}

export async function getProductsForCategories(
	productCategories: ProductCategory[]
): Promise<Product[]> {
	const products = await Promise.all(
		productCategories.map(async (productCategory) => {
			const json = await webparking.get(`product-categories/${productCategory.id}/products`).json();
			return json.data;
		})
	);
	return products.flat();
}

export async function getProducts(params?: { type: 'regular' | 'bulk' }): Promise<Product[]> {
	const json = await webparking.get(`products?product_type=${params?.type ?? 'regular'}`).json();
	// TODO: type json with Webparking types
	// TODO: merge "regular" and "bulk" Webparking products
	// TODO: merge with WordPress product enrichment data
	// TODO: return models/Product/Product[]
	return json.data;
}

export async function getNonSubscribableProducts(): Promise<NonSubscribableProduct[]> {
	const json = await webparking.get(`non-subscribable-products`).json();
	// TODO: type json with Webparking types
	// TODO: merge with WordPress product enrichment data
	// TODO: return models/NonSubscribableProduct/NonSubscribableProduct[]
	return json.data;
}

export async function getOrderQuantitiesForCategories(
	productCategories: ProductCategory[]
): Promise<OrderQuantity[]> {
	const quantities = await Promise.all(
		productCategories.map(async (productCategory) => {
			const json = await webparking
				.get(`product-categories/${productCategory.id}/order-quantities`)
				.json();
			return json.data;
		})
	);
	return quantities.flat();
}

export async function getOrderQuantities(): Promise<OrderQuantity[]> {
	const json = await webparking.get(`order-quantities`).json();
	// TODO: type json with Webparking types
	// TODO: return models/Order/OrderQuantity[]
	return json.data;
}

export async function getOrderFrequencies(): Promise<OrderFrequency[]> {
	const json = await webparking.get(`order-frequencies`).json();
	// TODO: type json with Webparking types
	// TODO: return models/Order/Orderfrequency[]
	return json.data;
}
