import { getContext, setContext } from 'svelte';
import { writable } from 'svelte/store';

export interface CheckoutData {
	[key: string]: string | number | null | boolean;
}

function createCheckoutStore() {
	let resolveInitialPromise: (checkout: CheckoutData) => void = () => {};
	const { subscribe, set, update } = writable(
		new Promise((resolve, reject) => {
			resolveInitialPromise = resolve;
		})
	);
	return {
		subscribe,
		onMount: () => {
			let checkout = JSON.parse(localStorage.getItem('checkout') ?? '{}');
			if (checkout === null) {
				checkout = getDefaultCheckout();
				localStorage.setItem('checkout', JSON.stringify(checkout));
			}
			resolveInitialPromise(checkout);
			set(Promise.resolve(checkout));
		},
		setCheckout: (checkout: CheckoutData) => {
			resolveInitialPromise(checkout);
			set(Promise.resolve(checkout));
			localStorage.setItem('checkout', JSON.stringify(checkout));
		},
		clearCheckout: () => {
			const checkout = getDefaultCheckout();
			resolveInitialPromise(checkout);
			set(Promise.resolve(checkout));
			localStorage.setItem('checkout', JSON.stringify(checkout));
		}
	};
}

const checkoutStore = createCheckoutStore();

export function initCheckoutStore() {
	setContext('checkout', checkoutStore);
}

export function getCheckoutStore(): CheckoutData {
	return getContext('checkout');
}

function getDefaultCheckout(): CheckoutData {
	return {
		currentStep: 2,
		paymentMethod: null,
		paymentMethodIssuer: null,
		paymentMethodIssuerError: null,
		isPaymentMethodSubmitted: false,
		errorOrderPaymentCreatedAt: null,
		advocateId: null
	};
}
