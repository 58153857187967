import type { Modal } from '$models/Modal/Modal';
import type { ModalStore, ModalStoreData } from '$models/Store/ModalStore';
import { getContext, setContext } from 'svelte';
import { writable } from 'svelte/store';

function createModalStore() {
	const { subscribe, set, update } = writable<Modal[]>([]);

	function trigger(modal: Modal) {
		update((modals) => {
			return [...modals, modal];
		});
	}

	function close() {
		update((modals) => {
			if (modals.length > 0) {
				modals.shift();
			}

			return modals;
		});
	}

	function clear() {
		set([]);
	}

	return {
		subscribe,
		trigger,
		close,
		clear
	};
}

const modalStore = createModalStore();

export function initModalStore() {
	setContext('modals', modalStore);
}

export function getModalStore() {
	return getContext<ModalStore<ModalStoreData>>('modals');
}
